<template>
    <Calendar />
</template>

<script>
import Calendar from '@apps/Calendar'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Calendar
    }
}
</script>